import { Component, OnInit } from "@angular/core";
import { AdminDashpordService } from "src/app/services/admin-dashpord.service";

@Component({
  selector: "app-expert-dashboard",
  templateUrl: "./expert-dashboard.component.html",
  styleUrls: ["./expert-dashboard.component.css"],
})
export class ExpertDashboardComponent implements OnInit {
  totalExpertActivities: number = 0;
  totalExpertPackages: number = 0;
  totalExpertActivitiesBooks: number = 0;
  totalExpertActivitySales: number = 0;
  totalExpertPackagesBooks: number = 0;
  totalExpertPackageSales: number = 0;

  // VerifiedUser: number = 0;
  // RegisteredUser: number = 0;
  // CreatedByAdmin: number = 0;
  // CreatedByExpert: number = 0;
  // CreatedByUser: number = 0;
  // NumberOfBooking: number = 0;
  // PageView: number;
  // NewVisitor: number;
  // TotalVisit: number;
  // TotalSales: number;
  // adminDashpordData;

  constructor(private adminService: AdminDashpordService) {}

  ngOnInit() {
    this.getDashpordDate();
  }

  getDashpordDate() {
    this.adminService.getDashboardDataForExpert().subscribe((data: any) => {
      this.totalExpertActivities = data.totalExpertActivities;
      this.totalExpertPackages = data.totalExpertPackages;
      this.totalExpertActivitiesBooks = data.totalExpertActivitiesBooks;
      this.totalExpertPackagesBooks = data.totalExpertPackagesBooks;
      this.totalExpertActivitySales = data.totalExpertActivitySales;
      this.totalExpertPackageSales = data.totalExpertPackageSales;
      // this.VerifiedUser = data.totalVerifiedUsers;
      // this.RegisteredUser = data.totalRegisteredUsers;
      // this.CreatedByAdmin = data.totalAdminPackage;
      // this.CreatedByExpert = data.totalExpertPackage;
      // this.CreatedByUser = data.totalUserPackage;
      // this.TotalSales = data.totalSales;
      // this.NumberOfBooking = data.totalBooks;
    });
  }
}
