<div class="page-wrapper" style="background-color: white !important">
  <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-6">
              <div class="log-reg-form">
                <div class="log-reg-head">
                  <h2>تسجيل الدخول</h2>
                </div>
                <div class="log-reg-body">
                  <form>
                    <div class="form-group">
                      <label for="email">البريد الالكتروني :</label>
                      <div class="input-group">
                        <i class="icon_set_1_icon-29"></i>
                        <input type="text" class="form-control">
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="email">كلمة المرور :</label>
                      <div class="input-group">
                        <i class="icon_set_1_icon-29"></i>
                        <input type="text" class="form-control">
                      </div>
                      <a href="javascript:void(0);" class="forget">نسيت كلمة المرور ؟</a>
                    </div>
                    <div class="form-group text-center">
                      <button class="cust-btn">دخول</button>
                      <span class="or">أو</span>
                      <button class="cust-btn google-plus">جوجل بلس</button>
                      <button class="cust-btn facebook">فيس بوك</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="advantages">
                <div class="advantages-head">
                  <h4>تريبات توفر لك : </h4>
                </div>
                <div class="advantages-body">
                  <ul class="advantages-list">
                    <li>
                      <i class="icon_set_1_icon-29"></i>
                      <p>
                        أفضل عروض شركات الطيران الدولية لتختار بينهم .
                      </p>
                    </li>
                    <li>
                      <i class="icon_set_1_icon-29"></i>
                      <p>
                        أفضل عروض شركات الطيران الدولية لتختار بينهم .
                      </p>
                    </li>
                    <li>
                      <i class="icon_set_1_icon-29"></i>
                      <p>
                        أفضل عروض شركات الطيران الدولية لتختار بينهم .
                      </p>
                    </li>
                    <li>
                      <i class="icon_set_1_icon-29"></i>
                      <p>
                        أفضل عروض شركات الطيران الدولية لتختار بينهم .
                      </p>
                    </li>
                    <li>
                      <i class="icon_set_1_icon-29"></i>
                      <p>
                        أفضل عروض شركات الطيران الدولية لتختار بينهم .
                      </p>
                    </li>
                    <li>
                      <i class="icon_set_1_icon-29"></i>
                      <p>
                        أفضل عروض شركات الطيران الدولية لتختار بينهم .
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-12 absolute-layer">
              <div class="flip">
                <div class="back">
                  <div class="register-adv">
                    <div class="register-adv-head">
                      <h2>تريبات دوت كوم</h2>
                    </div>
                    <div class="register-adv-body">
                      <p>
                        استمتع بالحصول على افضل المميزات والعروض فقط عند تسجيلك في تريبات دوت كوم
                      </p>
                      <button class="cust-btn" id="register-btn">سجل الآن</button>
                    </div>
                  </div>
                </div>
                <div class="front">
                  <div class="log-reg-form">
                    <div class="log-reg-head">
                      <h2 class="">تسجيل حساب جديد</h2>
                    </div>
                    <div class="log-reg-body">
                      <form class="login-form">
                        <div class="form-group">
                          <label for="name">اسمك : </label>
                          <div class="input-group">
                            <i class="icon_set_1_icon-29"></i>
                            <input type="text" class="form-control">
                          </div>
                        </div>
                        <div class="form-group">
                          <label for="name">البريد الالكترونى : </label>
                          <div class="input-group">
                            <i class="icon_set_1_icon-84"></i>
                            <input type="email" class="form-control">
                          </div>
                        </div>
                        <div class="form-group">
                          <label for="name">كلمة المرور : </label>
                          <div class="input-group">
                            <i class="icon_set_1_icon-29"></i>
                            <input type="password" class="form-control">
                          </div>
                        </div>
                        <div class="form-group">
                          <label for="name">تأكيد كلمة المرور : </label>
                          <div class="input-group">
                            <i class="icon_set_1_icon-29"></i>
                            <input type="password" class="form-control">
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="check-box">
                            <input type="checkbox" id="accept">
                            <label for="accept">
                              أوافق على
                              <a href="javascript:void(0);">الشروط والأحكام</a>
                            </label>
                          </div>
                        </div>
                        <div class="form-group">
                          <button class="cust-btn">إحجز الآن</button>
                          <a href="javascript:void(0);" id="login-btn">لدي حساب بالفعل</a>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <header class="header">
    <div class="container">
      <div class="top-header">
        <div class="row">
          <div class="col-sm-4">
            <div class="account">
              <a href="javascript:void(0);" (click)="Logout()">
                Logout </a>
              <a [routerLink]="['/user/profile']">
                <img [src]="imagePath"> <span>{{Name}}</span> </a>
            </div>
            <!-- End Account -->
          </div>
          <!-- End col -->
          <div class="col-sm-8">
            <div class="lang-cur">
              <div class="select-box">
                <select class="lang">
                  <option>عربي</option>
                  <option>En</option>
                </select>
                <div class="select-selected">
                  <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;">Arabic</font>
                  </font>
                </div>
                <div class="select-items select-hide">
                  <div>عربي</div>
                  <div>En</div>
                </div>
              </div><!-- End Custom Select -->
              <div class="select-box">
                <select class="currency">
                  <option>دولار $</option>
                  <option>ريال السعوديSAR</option>
                  <option>الجنيه المصري </option>
                </select>
                <div class="select-selected">
                  <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;">$ $</font>
                  </font>
                </div>
                <div class="select-items select-hide">
                  <div>دولار $</div>
                  <div>ريال السعوديSAR</div>
                  <div>الجنيه المصري </div>
                </div>
              </div><!-- End Custom Select -->
            </div><!-- End Lang Cur -->
          </div>
          <!-- End col -->
        </div>
        <!-- End Row -->
      </div>
      <!-- End Top Header -->
      <nav class="navbar  navbar-expand-lg">
        <a class="navbar-brand" [routerLink]="['/']">تريبـــــــات</a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
          aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item active">
              <a class="nav-link" [routerLink]="['/']"> الرئيسيه
                <span class="sr-only">(current)</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="javascript:void(0);">الأنشطه</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="javascript:void(0);">الفنادق</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="javascript:void(0);">الطيران</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="javascript:void(0);">السيارات </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
    <!-- End Container -->
  </header>
  <!-- End header -->
  <!-- === Start Profile Intro  Section -->
  <section class="profile-intro">
    <div class="container">
      <h1 class="text-center">مرحبا بك فى تريبات دوت كوم </h1>
    </div>
    <!-- End Container -->
  </section>
  <!-- === End Profile Intro  Section -->
  <!-- === Start Profile Cont  Section == -->
  <section class="profile-cont">
    <div class="container">
      <div class="row">
        <div class="col-lg-3">
          <aside class="profile-aside">
            <div class="img-name-profile">
              <div class="user-img">
                <img (click)="OpenFileDailog()" [src]="imagePath" style="border-radius: 50%;" alt="صورة المستخدم">
                <input type="file" style="display: none" name="UploadProfilePic" (change)="imageUpload($event)" />
              </div>
              <!-- End User Img -->
              <h3 (click)="EditName()" id="EditName">{{Name}} </h3>
            </div>
            <!-- End Img Name -->
            <div class="nav flex-column nav-pills profile-tabs" id="profile-tabs" role="tablist"
              aria-orientation="vertical">
              <a class="nav-link active" id="user-info-tab" data-toggle="pill" [routerLink]="['/user/profile']"
                role="tab" aria-controls="user-info" aria-selected="true">
                <i class=" icon_set_1_icon-29"></i> البيانات الشخصي</a>
              <a class="nav-link" id="my-reserv-tab" data-toggle="pill" [routerLink]="['/user/reservation']" role="tab"
                aria-controls="my-reserv-tab" aria-selected="false">
                <i class="icon_set_1_icon-35"></i>
                حجوزاتى</a>
              <!-- <a class="nav-link" id="my-reserv-tab" data-toggle="pill" [routerLink]="['/user/changepassword']" role="tab" aria-controls="my-reserv-tab"
                                aria-selected="false">
                                <i class="icon-lock-7"></i>
                                تغيير كلمة السر
                            </a> -->
            </div>
            <!-- End profile tabs -->
          </aside>
          <!-- End Aside -->
        </div>
        <!-- End Col -->
        <div class="col-lg-9">
          <router-outlet></router-outlet>
        </div>

      </div>
      <!-- End Row -->
    </div>
    <!-- End Container -->
  </section>
  <!-- === End  Profile Cont  Section == -->
  <!-- === Start Footer  === -->
  <footer class="footer">
    <div class="container">
      <div class="top-footer">
        <div class="row">
          <div class="col-lg-5 col-md-5 col-xs-12">
            <div class="about-footer">
              <a href="#"><img src="../../assets/imgs/logo.svg" alt="TriPpat"></a>
              <p>دليلك الشخصي حول العالم</p>
            </div>
            <!-- End About -->
          </div>
          <!-- End Col -->
          <div class="col-lg-4 col-md-4 col-xs-12">
            <div class="footer-links">
              <ul class="links">
                <li>
                  <a href="javascript:void(0);">سياسة الخصوصيه </a>
                </li>
                <!-- <li>
                                        <a href="javascript:void(0);">الأسئلة الشائعة</a>
                                    </li> -->
                <li>
                  <a href="javascript:void(0);">المدونه</a>
                </li>
                <li>
                  <a href="javascript:void(0);">شروط الإستخدام</a>
                </li>
                <li>
                  <a href="javascript:void(0);">خدمة العملاء</a>
                </li>
              </ul>
            </div>
            <!-- End Footer Links -->
          </div>
          <!-- End Col -->
          <div class="col-lg-3 col-md-3 col-xs-12">
            <div class="Contact">
              <h3>تواصل معنا </h3>
              <ul class="contact-way">
                <li>
                  <i class="icon_set_1_icon-93"></i>966-58003338
                </li>
                <li>
                  <i class=" icon_set_1_icon-84"></i>hello@trippat.com
                </li>
              </ul>
              <ul class="social-contacts">
                <li>
                  <a href="javascript:void(0);">
                    <i class="fs1" aria-hidden="true" data-icon="&#xe094;"></i>
                  </a>
                </li>
                <!-- <li>
                                        <a href="javascript:void(0);">
                                            <i class="fs1" aria-hidden="true" data-icon="&#xe093;"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);">
                                            <i class="fs1" aria-hidden="true" data-icon="&#xe096;"></i>
                                        </a>
                                    </li> -->
                <li>
                  <a href="javascript:void(0);">
                    <i class="fs1" aria-hidden="true" data-icon="&#xe09a;"></i>
                  </a>
                </li>
              </ul>
            </div>
            <!-- End Footer Links -->
          </div>
          <!-- End Col -->
        </div>
        <!-- End Row -->
      </div>
      <!-- End Top Footer -->
      <div class="bottom-footer">
        <h4 class="copy-right">حقوق النشر محفوظة &copy; تريبات 2018</h4>
        <div class="payments">
          <img src="../../assets/imgs/footer/master-card.svg">
          <img src="../../assets/imgs/footer/visa.png">
        </div>
        <!-- End Payments -->
      </div>
      <!-- End Bottom Footer -->
    </div>
    <!-- End Container -->
  </footer>
  <!-- === End Footer === -->
</div>
<!-- End Page wrapper -->
