import {
  CanActivateChild,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  Router,
} from "@angular/router";
import { Injectable } from "@angular/core";
import { SessionManagement } from "../session-management";
import { AppConfig } from "../app.config";
@Injectable({
  providedIn: "root",
})
export class AdminAuthGuard implements CanActivateChild {
  constructor(
    private router: Router,
    private sessionManagement: SessionManagement
  ) {}
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = this.sessionManagement.GetUser();
    const url = state.url;

    if (url.includes("admin") && user && user.Role.Name === "Admin") {
      return true;
    }

    if (url.includes("expert") && user && user.Role.Name === "Expert") {
      return true;
    }

    this.router.navigateByUrl("/");
    return false;

    // if (user == null) {
    //   this.router.navigateByUrl("/");
    //   return false;
    // } else {
    //   return true;
    // if (user.RoleId == AppConfig.Role.Admin) {
    //   return true;
    // } else {
    //   if (user.RoleId == AppConfig.Role.User) {
    //     this.router.navigateByUrl("/");
    //     console.log(user.RoleId);
    //     return false;
    //   } else if (user.RoleId === AppConfig.Role.Agency) {
    //     this.router.navigateByUrl("/expert");
    //     console.log(user.RoleId);
    //     return false;
    //   } else {
    //     this.router.navigateByUrl("/");
    //     console.log(user.RoleId);
    //     return false;
    //   }
    // }
    // }
  }
}
