import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SiteVisitor } from '../models/classes/siteVisitor';
import { promise } from 'protractor';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SiteVisitService {
  ipAddress: string;

  constructor(private http: HttpClient) {
  }

  async Create(model: SiteVisitor) {
    model.IP = await this.GetIPAddress();
    return this.http.post(environment.API_URL +'/api/sitevisitor/create', model).toPromise().then(data => {
      return data;
    });
  }


  CountPageview() {
    return this.http.get(environment.API_URL +'/api/sitevisitor/count/all');
  }

  async GetIPAddress() {
    this.ipAddress = await this.http.get<{ ip: string }>('https://jsonip.com').toPromise().then(data => {
      return data.ip;
    });
    return this.ipAddress;
  }
}
