<div class="container" *ngIf="checkoutData">
  <div id="logo" class="pull-right" style="float: left; margin-top: 20px;"><img src="assets/img/newLogo.png" width="160"
      height="54" alt="City tours"></div>
  <div class="row">
    <div class="col-lg-12">
      <div class="invoice-title">
        <h2>فاتورة</h2><br />
        <h3 class="left">رقم الفاتورة # {{checkoutData?.CheckoutUid}}</h3>
      </div>
      <hr>

      <div class="row">
        <div class="col-sm-6">
          <address>
            <strong>وسيلة الدفع:</strong>
            <br>
            <ng-container *ngIf="!checkoutData?.PayWithCash">
              بطاقة الدفع المسبق
            </ng-container>
            <ng-container *ngIf="checkoutData?.PayWithCash">
              كاش
            </ng-container>
            <br>
          </address>
        </div>
        <div class="col-sm-6 textRight">
          <address>
            <strong>تاريخ الحجز:</strong><br>
            {{checkoutData?.CreatedOn | date:'mediumDate'}}
            <!-- March 7, 2014 -->
            <br><br>
          </address>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <address>
            <strong>الإسم:</strong><br>
            {{checkoutData?.FirstName}} {{checkoutData?.LastName}}
          </address>
        </div>
        <div class="col-sm-6 textRight">
          <address>
            <strong>البريد الإلكتروني</strong><br>
            {{checkoutData?.Email}}<br>
          </address>
        </div>
      </div>
    </div>
  </div>


  <div class="row">
    <div class="col-lg-12">
      <div class="panel panel-default">
        <div class="panel-heading">
          <h3 class="panel-title"><strong>تفاصيل الطلب</strong></h3>
        </div>
        <div class="panel-body">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <td><strong>المنتج</strong></td>
                  <td class="text-center"><strong>السعر</strong></td>
                </tr>
              </thead>
              <tbody>
                <!-- foreach ($order->lineItems as $line) or some such thing here -->
                <tr>
                  <td *ngIf="checkoutData?.UserBooking?.Activity">{{checkoutData?.UserBooking?.Activity?.Name}}</td>
                  <td *ngIf="checkoutData?.UserBooking?.Package">{{checkoutData?.UserBooking?.Package?.Name}}</td>
                  <td class="text-center">{{checkoutData?.UserBooking?.TotalPrice}} ر.س</td>
                </tr>
                <tr>
                  <td class="no-line text-right"><strong>المجموع</strong></td>
                  <td class="no-line text-center"><strong>{{checkoutData?.UserBooking?.TotalPrice}} ر.س</strong></td>
                </tr>

              </tbody>
            </table>
            <p>شامل ضريبة القيمة المضافة</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="checkoutData?.PayWithCash">
    <div class="col-lg-12">
      <button (click)="open()">ادفع الان</button>
    </div>
  </div>
</div>
