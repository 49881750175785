<!-- End section -->
<section id="search_container">
  <div id="search">
    <ul class="nav nav-tabs">
      <li><a [ngClass]="{'active show': showPackageSearch}"
          (click)="activeTap('showPackageSearch', 'showActivitySearch')">رحلات</a></li>
      <li><a [ngClass]="{'active show': showActivitySearch}"
          (click)="activeTap('showActivitySearch', 'showPackageSearch')">نشاطات</a></li>
    </ul>

    <div class="tab-content">

      <div class="tab-pane" [ngClass]="{'active show': showActivitySearch}" id="activities">
        <h3>إبحث في النشاطات</h3>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label>المدينة أو الدولة</label>
              <mat-form-field class="example-chip-list">
                <mat-chip-list #chipList>
                  <mat-chip *ngFor="let dest of destinations" [selectable]="true" [removable]="true"
                    (removed)="remove(dest.Key)">
                    {{dest.Name}}
                    <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
                  </mat-chip>
                  <input class="input-style" placeholder="أكتب اسماء المدن أو الدول" #destinationInput
                    [formControl]="destinationCtr" [matAutocomplete]="auto" [matChipInputFor]="chipList"
                    [matChipInputAddOnBlur]="true">
                </mat-chip-list>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                  <mat-option *ngFor="let dest of searchResult" [value]="dest">
                    {{dest.Name}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label><i class="icon-calendar-7"></i> تاربخ بداية الرحلة</label>
              <input type="text" class="form-control" placeholder="dd/mm/yyyy" [(bsValue)]="searchDate"
                value="{{ today | date:'dd/MM/yyyy' }}" bsDatepicker [minDate]="today"
                [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }" />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>نوع النشاط</label><br />
              <select class="ddslick form-control" name="category" [(ngModel)]="selectedCategory">
                <option value="0" selected>جميع النشاطات</option>
                <option *ngFor="let cat of activitiesCats" [value]="cat.Id">{{cat.Name}}</option>
              </select>
            </div>
          </div>
          <div class="col-md-3 col-sm-3">
            <div class="form-group">
              <label>بالغين</label>
              <div class="numbers-row">
                <input type="text" value="1" class="qty2 form-control" name="adults" [(ngModel)]="adults">
                <div class="inc button_inc" (click)="countControl('adults',1)">+</div>
                <div class="dec button_inc" (click)="countControl('adults', -1)">-</div>
              </div>
            </div>
          </div>
          <div class="col-md-3 col-sm-3">
            <div class="form-group">
              <label>أطفال</label>
              <div class="numbers-row">
                <input type="text" value="0" class="qty2 form-control" name="children" [(ngModel)]="children">
                <div class="inc button_inc" (click)="countControl('children',1)">+</div>
                <div class="dec button_inc" (click)="countControl('children',-1)">-</div>
              </div>
            </div>
          </div>
        </div>
        <!-- End row -->

        <hr>
        <input type="submit" value="إبحث الآن" class="btn_1" id="submit-review" (click)="search()">
      </div>
      <div class="tab-pane" [ngClass]="{'active show': showPackageSearch}" id="packages">
        <h3>إبحث في رحلاتنا المميزة</h3>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label>المدينة أو الدولة</label>
              <mat-form-field class="example-chip-list">
                <mat-chip-list #chipList>
                  <mat-chip *ngFor="let dest of destinations" [selectable]="true" [removable]="true"
                    (removed)="remove(dest.Key)">
                    {{dest.Name}}
                    <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
                  </mat-chip>
                  <input class="input-style" placeholder="أكتب اسماء المدن أو الدول" #destinationInput
                    [formControl]="destinationCtr" [matAutocomplete]="auto" [matChipInputFor]="chipList"
                    [matChipInputAddOnBlur]="true">
                </mat-chip-list>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                  <mat-option *ngFor="let dest of searchResult" [value]="dest">
                    {{dest.Name}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>
        </div>
        <!-- End row -->
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label><i class="icon-calendar-7"></i> تاربخ بداية الرحلة</label>
              <input type="text" class="form-control" placeholder="dd/mm/yyyy" [(bsValue)]="searchDate"
                value="{{ today | date:'dd/MM/yyyy' }}" bsDatepicker [minDate]="today"
                [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }" />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>عدد الأيام</label><br />
              <select class="ddslick form-control" name="duration" [(ngModel)]="duration">
                <option value="0" selected>اختر عدد الأيام</option>
                <option value="2,4">من 2 إلى 4 أيام</option>
                <option value="5,7">من 5 إلى 7 أيام</option>
                <option value="8,10">من 8 إلى 10 أيام</option>
                <option value="11,14">من 11 إلى 14 يوم</option>
                <option value="14">أكثر من 14 يوم</option>
              </select>
            </div>
          </div>
          <div class="col-md-3 col-sm-3">
            <div class="form-group">
              <label>بالغين</label>
              <div class="numbers-row">
                <input type="text" value="1" class="qty2 form-control" name="adults" [(ngModel)]="adults">
                <div class="inc button_inc" (click)="countControl('adults',1)">+</div>
                <div class="dec button_inc" (click)="countControl('adults', -1)">-</div>
              </div>
            </div>
          </div>
          <div class="col-md-3 col-sm-3">
            <div class="form-group">
              <label>أطفال</label>
              <div class="numbers-row">
                <input type="text" value="0" class="qty2 form-control" name="children" [(ngModel)]="children">
                <div class="inc button_inc" (click)="countControl('children',1)">+</div>
                <div class="dec button_inc" (click)="countControl('children',-1)">-</div>
              </div>
            </div>
          </div>

        </div>
        <!-- End row -->
        <hr>
        <input type="submit" value="إبحث الآن" class="btn_1" id="submit-review" (click)="search()">
      </div>

    </div>
  </div>
</section>
<!-- End hero -->
<main>

  <div class="container margin_60" *ngIf="packages.length">

    <div class="main_title">
      <h2>تجارب <span>مميزة</span></h2>
    </div>

    <div class="row">

      <div class="col-lg-4 col-md-6 wow " *ngFor="let package of packages">
        <app-trip-box [trip]="package" type="package"></app-trip-box>
      </div><!-- End col -->

    </div><!-- End row -->

    <p class="text-center nopadding">
      <a [routerLink]="['/offers']" class="btn_1 medium"><i class="icon-eye-7"></i>تصفح جميع تجاربنا</a>
    </p>
  </div><!-- End container -->

  <div class="container margin_60" *ngIf="activities.length">

    <div class="main_title">
      <h2>نشاطات <span>مميزة</span></h2>
    </div>

    <div class="row">

      <div class="col-lg-4 col-md-6 wow " *ngFor="let activity of activities">
        <app-trip-box [trip]="activity" type="activity"></app-trip-box>
      </div><!-- End col -->

    </div><!-- End row -->

    <p class="text-center nopadding">
      <a [routerLink]="['/activitypannel']" class="btn_1 medium"><i class="icon-eye-7"></i>تصفح جميع نشاطاتنا</a>
    </p>
  </div><!-- End container -->


  <!-- **************************** Campaigns **************************** -->
  <!-- **************************** Campaigns **************************** -->
  <div class="container margin_60 theCampaigns" *ngIf="campaigns.length">

    <div class="main_title">
      <h2>{{"TheCampaigns" | translate}}</h2>
    </div>

    <div class="row">

      <div class="col-lg-4 col-md-6 wow " *ngFor="let campaign of campaigns">
        <div class="tour_container">
          <!-- <div class="ribbon_3"><span>تقييم مرتفع</span></div> -->
          <div class="img_container">
            <a [routerLink]="['/campaign/' + campaign.Id]">
              <img [src]="campaign.ImagePath" width="800" height="533" class="img-fluid" alt="Image">
              <div class="short_info">
                <i>{{campaign.Name}}</i>
                <!-- {{campaign.Name}}<span>ddd</span> -->
              </div>
            </a>
          </div>
        </div><!-- End box tour -->
      </div><!-- End col -->

    </div><!-- End row -->

    <!-- <p class="text-center nopadding" *ngIf="campaignsCount > campaigns.length">
      <a [routerLink]="['/account/allExperts']" class="btn_1 medium"><i class="icon-eye-7"></i>تصفح جميع الخبراء
        ({{campaignsCount}}) </a>
    </p> -->
  </div><!-- End container -->
  <!-- **************************** Campaigns **************************** -->
  <!-- **************************** Campaigns **************************** -->



  <div class="container margin_60" *ngIf="experts.length">

    <div class="main_title">
      <h2>خبراءنا <span>المميزين</span></h2>
    </div>

    <div class="row">

      <div class="col-lg-4 col-md-6 wow " *ngFor="let expert of experts">
        <div class="tour_container">
          <!-- <div class="ribbon_3"><span>تقييم مرتفع</span></div> -->
          <div class="img_container">
            <a [routerLink]="['/account/v/' + expert.Id]">
              <img [src]="expert.UserProfile.ProfilePicPath" width="800" height="533" class="img-fluid" alt="Image">
              <div class="short_info">
                <i></i>{{expert.UserProfile.FirstName}} {{expert.UserProfile.LastName}}<span></span>
              </div>
            </a>
          </div>
        </div><!-- End box tour -->
      </div><!-- End col -->

    </div><!-- End row -->

    <p class="text-center nopadding" *ngIf="expertsCount > experts.length">
      <a [routerLink]="['/account/allExperts']" class="btn_1 medium"><i class="icon-eye-7"></i>تصفح جميع الخبراء
        ({{expertsCount}}) </a>
    </p>
  </div><!-- End container -->


</main>
<!-- End main -->
