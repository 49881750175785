import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "../home/home.component";
import { LayoutComponent } from "../layout/layout.component";
import { AdminAuthGuard } from "../guards/admin.guard";
import { UserGuard } from "../guards/user.guard";
import { AdminNewLayoutComponent } from "../admin-new-layout/admin-new-layout.component";
import { InvoiceComponent } from "../offer/invoice/invoice.component";
import { ExpertDashboardComponent } from "../expert/expert-dashboard/expert-dashboard.component";
import { PrivacyPolicyComponent } from "../static/privacy-policy/privacy-policy.component";
import { CampaignsViewComponent } from "../campaigns/campaigns-view/campaigns-view.component";

const routes: Routes = [
  {
    path: "",
    component: LayoutComponent,
    children: [
      {
        path: "",
        component: HomeComponent,
        data: { title: "Titles.Home", description: "Descriptions.Home" },
      },
      {
        path: "home",
        component: HomeComponent,
        data: { title: "Titles.Home", description: "Descriptions.Home" },
      },
      {
        path: "account",
        loadChildren: () => import('../account/account.module').then(m => m.AccountModule),
      },
      { path: "login", loadChildren: () => import('../login/login.module').then(m => m.LoginModule) },
      {
        path: "createpassword/:id",
        loadChildren:
          () => import('../passwordcreater/passwordcreater.module').then(m => m.PasswordcreaterModule),
      },
      { path: "about", loadChildren: () => import('../about/about.module').then(m => m.AboutModule) },
      {
        path: "verify/:id",
        loadChildren: () => import('../verify/verify.module').then(m => m.VerifyModule),
      },
      {
        path: "resetpassword",
        loadChildren:
          () => import('../reset-password/reset-password.module').then(m => m.ResetPasswordModule),
      },
      //{ path: 'resetpassword', loadChildren: '../reset-password/reset-password.module#ResetPasswordModule' },
      { path: "offers", loadChildren: () => import('../offer/offer.module').then(m => m.OfferModule) },
      // { path: 'offer/detail/:id', loadChildren: '../offer-detail/offer-detail.module#OfferDetailModule' },
      {
        path: "offer/detail",
        loadChildren: () => import('../offer-detail/offer-detail.module').then(m => m.OfferDetailModule),
      },
      { path: "error", loadChildren: () => import('../error/error.module').then(m => m.ErrorModule) },
      {
        path: "privacy-policy",
        component: PrivacyPolicyComponent,
      },
      {
        path: "termandcondition",
        loadChildren:
          () => import('../terms-and-condition/terms-and-condition.module').then(m => m.TermsAndConditionModule),
      },
      // {
      //   path: "contactus",
      //   loadChildren: "../contact/contact.module#ContactModule",
      // },
      {
        path: "customtrip",
        loadChildren: () => import('../custom-trip/custom-trip.module').then(m => m.CustomTripModule),
      },
      // {
      //   path: "qualityandassurance",
      //   loadChildren:
      //     "../quality-and-assurance/quality-and-assurance.module#QualityAndAssuranceModule",
      // },
      {
        path: "agencyregister",
        loadChildren:
          () => import('../agency-register/agency-register.module').then(m => m.AgencyRegisterModule),
      },
      {
        path: "tripplaner",
        loadChildren:
          () => import('../main-trip-planner/main-trip-planner.module').then(m => m.MainTripPlannerModule),
      },
      {
        path: "activitypannel",
        loadChildren:
          () => import('../activity-pannel/activity-pannel.module').then(m => m.ActivityPannelModule),
      },
      {
        path: "campaign/:campaignId",
        component: CampaignsViewComponent,
      },
      //{path:'tripplaner',loadChildren:'../tripplaner/tripplaner.module#TripplanerModule'}
    ],
  },
  { path: "voucher", loadChildren: () => import('../voucher/voucher.module').then(m => m.VoucherModule) },
  {
    path: "user",
    loadChildren: () => import('../profile/profile.module').then(m => m.ProfileModule),
    canActivateChild: [UserGuard],
  },
  // {
  //   path: "user/mytrip",
  //   loadChildren: "../my-trip/my-trip.module#MyTripModule",
  //   canActivateChild: [UserGuard],
  // },
  {
    path: "editbooking",
    loadChildren: () => import('../edit-booking/edit-booking.module').then(m => m.EditBookingModule),
  },
  {
    path: "admin",
    component: AdminNewLayoutComponent,
    canActivateChild: [AdminAuthGuard],
    children: [
      {
        path: "",
        loadChildren: () => import('../dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: "dashboard",
        loadChildren: () => import('../dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      { path: "user", loadChildren: () => import('../user/user.module').then(m => m.UserModule) },
      {
        path: "package",
        loadChildren: () => import('../packages/packages.module').then(m => m.PackagesModule),
      },
      { path: "hotel", loadChildren: () => import('../hotel/hotel.module').then(m => m.HotelModule) },
      {
        path: "country",
        loadChildren: () => import('../country/country.module').then(m => m.CountryModule),
      },
      { path: "city", loadChildren: () => import('../city/city.module').then(m => m.CityModule) },
      {
        path: "activity",
        loadChildren: () => import('../activity/activity.module').then(m => m.ActivityModule),
      },
      {
        path: "service",
        loadChildren: () => import('../service/service.module').then(m => m.ServiceModule),
      },
      {
        path: "room",
        loadChildren: () => import('../room-type/room-type.module').then(m => m.RoomTypeModule),
      },
      {
        path: "packagecategory",
        loadChildren:
          () => import('../package-category/package-category.module').then(m => m.PackageCategoryModule),
      },
      {
        path: "activityinclude",
        loadChildren:
          () => import('../activity-include/activity-include.module').then(m => m.ActivityIncludeModule),
      },
      {
        path: "hotelinclude",
        loadChildren:
          () => import('../hotel-include/hotel-include.module').then(m => m.HotelIncludeModule),
      },
      {
        path: "activitycategory",
        loadChildren:
          () => import('../activity-category/activity-category.module').then(m => m.ActivityCategoryModule),
      },
      {
        path: "amenity",
        loadChildren: () => import('../amenity/amenity.module').then(m => m.AmenityModule),
      },
      {
        path: "mealplan",
        loadChildren: () => import('../meal-plan/meal-plan.module').then(m => m.MealPlanModule),
      },
      {
        path: "facility",
        loadChildren: () => import('../facility/facility.module').then(m => m.FacilityModule),
      },
      {
        path: "checkout",
        loadChildren:
          () => import('../checkout-detail/checkout-detail.module').then(m => m.CheckoutDetailModule),
      },
      {
        path: "pendingCheckouts",
        loadChildren:
          () => import('../pendingCheckouts/pending-checkouts.module').then(m => m.PendingCheckoutsModule),
      },
      {
        path: "campaigns",
        loadChildren: () => import('../campaigns/campaigns.module').then(m => m.CampaignsModule),
      },
      { path: "agency", loadChildren: () => import('../agency/agency.module').then(m => m.AgencyModule) },

      {
        path: "customtrip",
        loadChildren: () => import('../custom-trip/custom-trip.module').then(m => m.CustomTripModule),
      },
      {
        path: "planedtriplist",
        loadChildren:
          () => import('../plan-trip-list/plan-trip-list.module').then(m => m.PlanTripListModule),
      },
    ],
  },
  // {
  //   path: "agency",
  //   loadChildren: "../agency-layout/agency-layout.module#AgencyLayoutModule",
  //   canActivateChild: [AgencyAuthGuard],
  // },
  {
    path: "expert",
    component: AdminNewLayoutComponent,
    canActivateChild: [AdminAuthGuard],
    children: [
      { path: "", component: ExpertDashboardComponent },
      {
        path: "package",
        loadChildren: () => import('../packages/packages.module').then(m => m.PackagesModule),
      },
      {
        path: "activity",
        loadChildren: () => import('../activity/activity.module').then(m => m.ActivityModule),
      },
    ],
  },
  {
    path: "invoice/:checkoutId",
    component: InvoiceComponent,
  },
  { path: "**", redirectTo: "/" },
];
@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled", relativeLinkResolution: 'legacy', initialNavigation: 'enabledBlocking' }),
  ],
  declarations: [],
  exports: [RouterModule],
})
export class RoutingModule {}
