import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from "@angular/common/http";

import { Observable, from, throwError } from "rxjs";
import { switchMap, catchError } from "rxjs/operators";
import { SessionManagement } from "src/app/session-management";
import { ToastrService } from "ngx-toastr";
import { StorageService } from "src/app/shared/services/storage.service";

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(
    private toastr: ToastrService,
    private storageService: StorageService,
    private sessionManagement: SessionManagement
  ) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(Promise.all([this.getLang(), this.getToken()])).pipe(
      switchMap((val) => {
        const lang = val[0];
        const token = val[1];
        request = request.clone({
          headers: request.headers.set("Accept-Language", lang),
        });
        // remove req url cause it want work in localhost
        if (token && this.checkDomain(request)) {
          request = request.clone({
            headers: request.headers.set("Authorization", "Bearer " + token),
          });
        }
        return next.handle(request).pipe(
          catchError((error: any, caught: Observable<HttpEvent<any>>) => {
            if (error instanceof HttpErrorResponse) {
              if (error.status === 400) {
                if (Array.isArray(error.error.message)) {
                  error.error.message.map((msg) => {
                    this.ErrorToaster(msg);
                  });
                } else {
                  this.ErrorToaster(error.error.message);
                }
              } else if (
                error.status === 401 ||
                error.status === 400 ||
                error.status === 403 ||
                error.status === 500
              ) {
                console.log(error.error.message);
              }
            }
            return throwError(error);
          })
        );
      })
    );
  }

  checkDomain(request) {
    return (
      request.url.includes("trippat.com") || request.url.includes("localhost")
    );
  }

  getLang() {
    return Promise.resolve().then(() => {
      return this.storageService.get("Language") || "en";
    });
  }

  getToken() {
    return Promise.resolve().then(() => {
      return this.sessionManagement.GetUser()
        ? this.sessionManagement.GetUser().Token
        : "";
    });
  }

  ErrorToaster(message) {
    this.toastr.error(message, null, {
      timeOut: 5000,
      closeButton: false,
      positionClass: "toast-top-center",
    });
  }
}
