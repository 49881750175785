<form [formGroup]="formGroup">
  <div class="prtm-block" style="padding:30px;border-radius:10px;">
    <div class="row"> 
      <div class="col-lg-10 col-xs-7 sub-heading-traveler text-left mrgn-b-md">
        <span *ngIf="serialNo == 1">{{'Lead Traveler'| translate}}</span><span *ngIf="serialNo > 1 && serialNo < 10">Traveler
          0{{serialNo}}</span>
      </div>
      <div class="col-lg-2 col-xs-5 text-right ch-hover"><i class="fa fa-chevron-down" data-toggle="collapse"
          [attr.data-target]="'#demo'+serialNo"></i></div>
    </div>
    <div [id]="'demo'+serialNo" [ngClass]="{'collapse in': serialNo == 1, 'collapse':serialNo > 1 }">
      <div class="row">
        <div class="col-md-2">
          <div class="row">
            <div class="col-md-12 mrgn-b-lg text-left">
              <label>{{'Title'| translate}}</label>
              <select class="selectize-control form-control" formControlName="Gender">
                <option value="1">{{'Mr' |translate}}</option>
                <option value="2">{{'Mrs'| translate}}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="col-md-10">
          <div class="row">
            <div class="col-md-4 mrgn-b-lg text-left">
              <label>{{'First Name'| translate}}</label>
              <input type="text" class="form-control" name="fname" autocomplete="off" placeholder="{{'First Name'  | translate}}"
                formControlName="FirstName"
                [ngClass]="formGroup.controls['FirstName'].untouched || (formGroup.controls['FirstName'].touched && formGroup.controls['FirstName'].valid) ? 'valid':'notvalid'" />
            </div>

            <!-- <div class="col-md-4 mrgn-b-lg text-left">
            <label>Middle name</label>
            <input type="text" class="form-control" name="mname" placeholder="Middle name" formControlName="MiddleName" [ngClass]="formGroup.controls['MiddleName'].untouched || (formGroup.controls['MiddleName'].touched && formGroup.controls['MiddleName'].valid) ? 'valid':'notvalid'"/>
          </div> -->

            <div class="col-md-4 mrgn-b-lg text-left">
              <label>{{'Last Name'| translate}}</label>
              <input type="text" class="form-control" name="fname" autocomplete="off" placeholder="{{'Last Name' | translate}}"
                formControlName="FamilyName"
                [ngClass]="formGroup.controls['FamilyName'].untouched || (formGroup.controls['FamilyName'].touched && formGroup.controls['FamilyName'].valid) ? 'valid':'notvalid'" />
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="row">
      <div class="col-md-4 mrgn-b-lg text-left">
        <label>Date of birth</label>
        <div class="dob-details form-control" [ngClass]="formGroup.controls['DOB'].untouched || (formGroup.controls['DOB'].touched && formGroup.controls['DOB'].valid) ? 'valid':'notvalid'">
          <input type="text" class="form-control" formControlName="DOB" bsDatepicker
          [bsConfig]="{ dateInputFormat: 'MMMM DD,YYYY' }" /> 
          <i class="fa fa-calendar-o" ></i>
        </div>
      </div>

      <div class="col-md-4 mrgn-b-lg text-left">
        <label>Passport number</label>
        <input type="text" class="form-control" name="fname" placeholder="Passport number" formControlName="PassportNumber" [ngClass]="formGroup.controls['PassportNumber'].untouched || (formGroup.controls['PassportNumber'].touched && formGroup.controls['PassportNumber'].valid) ? 'valid':'notvalid'"/>
      </div>

      <div class="col-md-4 mrgn-b-lg text-left">
        <label>Passport expiry date</label>
        <div class="passport-details form-control" [ngClass]="formGroup.controls['PassportExpiry'].untouched || (formGroup.controls['PassportExpiry'].touched && formGroup.controls['PassportExpiry'].valid) ? 'valid':'notvalid'">
          <input type="text" class="form-control" formControlName="PassportExpiry" bsDatepicker
          [bsConfig]="{ dateInputFormat: 'MMMM DD,YYYY' }" /> <i class="fa fa-calendar-o"></i>
        </div>

      </div>
    </div> -->
    </div>
  </div>
</form> 