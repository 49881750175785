import { Component, OnInit, Input } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-image-description",
  templateUrl: "./image-description.component.html",
  styleUrls: ["./image-description.component.css"],
})
export class ImageDescriptionComponent implements OnInit {
  @Input() serialNo: number;
  @Input() data: any;
  formGroup: FormGroup;
  constructor(private _fb: FormBuilder, private translate: TranslateService) {}

  ngOnInit() {
    this.formGroup = this._fb.group({
      Id: [""],
      Description: [""],
      Name: [""],
    });
    // debugger
    if (this.data) {
      this.formGroup.patchValue(this.data);
    }
  }

  GetFormValues() {
    return this.formGroup.value;
  }

  reset() {
    // debugger
    this.ngOnInit();
  }
}
