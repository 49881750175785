import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ActivityCategoryMaster } from "../models/classes/activityCategoryMaster";
import { Data } from "../models/data";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ActivitycategorymasterService {
  constructor(private _http: HttpClient) {}

  GetAll() {
    const url = environment.API_URL + "/api/activitycategory/all";
    return this._http.get(url);
  }

  GetAllByPaging(page: number, page_size: number, search: string) {
    // debugger;
    var url =
      environment.API_URL +
      "/api/activitycategory/paging/all?page=" +
      page +
      "&page_size=" +
      page_size;
    if (search) {
      url =
        environment.API_URL +
        "/api/activitycategory/paging/all?page=" +
        page +
        "&page_size=" +
        page_size +
        "&search=" +
        search;
    }
    return this._http.get(url);
  }

  GetById(id: number) {
    // debugger
    const url = environment.API_URL + "/api/activitycategory/";
    return this._http.get(url + id);
  }

  Create(model: ActivityCategoryMaster) {
    const url = environment.API_URL + "/api/activitycategory/create";
    return this._http.post(url, model);
  }

  Update(id: number, model: ActivityCategoryMaster) {
    // debugger
    const url = environment.API_URL + "/api/activitycategory/update/" + id;
    return this._http.post(url, model);
  }

  Delete(id: number) {
    const url = environment.API_URL + "/api/activitycategory/delete/";
    return this._http.post(url + id, null);
  }

  IsActivCategoryExist(name: string, id: number) {
    // debugger
    let promise = new Promise<{ [key: string]: any } | null>(
      (resolve, reject) => {
        this._http
          .post(environment.API_URL + "/api/activitycategory/categoryexist", {
            Name: name,
            Id: id,
          })
          .subscribe((data: Data<string>) => {
            if (data.returnCode == 0) {
              resolve(null);
            } else {
              resolve({ exist: true });
            }
          });
      }
    );
    return promise;
  }
}
