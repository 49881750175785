import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ContactUs } from "../models/classes/contactus";
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: "root",
})
export class ContactService {
  constructor(private _http: HttpClient) {}

  GetAll() {
    // debugger
    const url = environment.API_URL + "/api/contact/all";
    return this._http.get(url);
  }

  GetAllByPaging(page: number, page_size: number) {
    // debugger;
    return this._http.get(
      environment.API_URL +
        "/api/contact/paging/all?page=" +
        page +
        "&page_size=" +
        page_size
    );
  }

  GetById(id: number) {
    // debugger
    const url = environment.API_URL + "/api/contact/all";
    return this._http.get(url + id);
  }

  Create(model: ContactUs) {
    // debugger
    const url = environment.API_URL + "/api/contact/create";
    return this._http.post(url, model);
  }

  Update(model: ContactUs) {
    // debugger
    const url = environment.API_URL + "/api/contact/update";
    return this._http.post(url, model);
  }

  Delete(id: number) {
    const url = environment.API_URL + "/api/contact/delete/";
    return this._http.post(url + id, null);
  }
}
