import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class CampaignsService {
  // httpOptions = { headers: { "Content-Type": "application/json" } };
  constructor(private _http: HttpClient) {}

  baseUrl = `${environment.API_URL}/api/packagecampaign`;

  getAll(page, page_size, search?) {
    return this._http.get(
      `${this.baseUrl}/paging/all?page=${page}&page_size=${page_size}${
        search ? "&search=" + search : ""
      }`
    );
  }

  getCampaignById(campaignId) {
    return this._http.get(`${this.baseUrl}/${campaignId}`);
  }

  createCampaign(data) {
    return this._http.post(`${this.baseUrl}/create`, data);
  }

  updateCampaign(campaignId, data) {
    return this._http.post(`${this.baseUrl}/update/${campaignId}`, data);
  }

  deleteCampaign(campaignId) {
    return this._http.post(`${this.baseUrl}/delete/${campaignId}`, {});
  }
}
