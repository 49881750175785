import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, AfterViewInit, Inject, PLATFORM_ID } from "@angular/core";
import { Router } from "@angular/router";
import { SessionManagement } from "../session-management";
import * as $ from "jquery";
import { Session } from "protractor";
import { TranslateService } from "@ngx-translate/core";
import { StorageService } from "../shared/services/storage.service";

@Component({
  selector: "app-admin-new-layout",
  templateUrl: "./admin-new-layout.component.html",
  styleUrls: ["./admin-new-layout.component.css"],
})
export class AdminNewLayoutComponent implements OnInit, AfterViewInit {
  imagePath = this.sessionManagement.GetUser().UserProfile.ProfilePicPath;
  Name = this.sessionManagement.GetUser().UserProfile.FirstName;
  Language;
  notExpert = true;
  constructor(
    private _router: Router,
    private translate: TranslateService,
    private storageService: StorageService,
    private sessionManagement: SessionManagement,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}
  ngOnInit() {
    if (this.storageService.get("Language") == "ar") {
      this.Language = "Arabic";
      if(isPlatformBrowser(this.platformId)){$("body").addClass("arabic");}
    } else {
      this.Language = "English";
      if(isPlatformBrowser(this.platformId)){
        $("[name='bootstrap-rtl']").remove();
        $("body").removeClass("arabic");
      }
    }
    if (
      JSON.parse(this.storageService.get("CurrentUser")).Role.Name == "Expert"
    ) {
      this.notExpert = false;
    }
  }

  ngAfterViewInit() {}

  Logout() {
    this.storageService.clear();
    this._router.navigateByUrl("/home");
  }

  Translate(lang: string) {
    // debugger
    this.translate.use(lang);
    if (lang == "ar") {
      this.Language = "Arabic";
      if(isPlatformBrowser(this.platformId)){
        $("body").addClass("arabic");
      }
    } else {
      this.Language = "English";
      if(isPlatformBrowser(this.platformId)){
        $("[name='bootstrap-rtl']").remove();
        $("body").removeClass("arabic");
      }
    }
    this.storageService.set("Language", lang);
    window.location.reload();
  }
}
