import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class TransactionService {
  constructor(private _http: HttpClient) {}

  GetAll(page: number, page_size: number, search: string, AgencyId: number) {
    // debugger
    var url =
      environment.API_URL +
      "/api/transaction/all?page=" +
      page +
      "&page_size=" +
      page_size;
    if (search) {
      url = url + "&search=" + search;
    }
    if (AgencyId) {
      url = url + "&agency=" + AgencyId;
    }
    return this._http.get(url);
  }

  GetById(id: number) {
    // debugger
    const url = environment.API_URL + "/api/transaction/user/";
    return this._http.get(url + id);
  }

  GetByTransactionId(id: string) {
    // debugger
    const url = environment.API_URL + "/api/transaction/user/transaction/";
    return this._http.get(url + id);
  }

  GetByUserId(id: number) {
    // debugger
    const url = environment.API_URL + "/api/transaction/user/";
    return this._http.get(url + id);
  }

  CountTransaction() {
    return this._http.get(
      environment.API_URL + "/api/transaction/count/transaction"
    );
  }
}
