import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Favourite } from "src/app/models/classes/favourite";
import { Service } from "src/app/services/service";
import { SessionManagement } from "src/app/session-management";

@Component({
  selector: "app-trip-box",
  templateUrl: "./trip-box.component.html",
  styleUrls: ["./trip-box.component.css"],
})
export class TripBoxComponent implements OnInit {
  @Input() adults;
  @Input() children;
  @Input() date;
  @Input() fav;
  @Input() isFav;
  @Input() set trip(val) {
    this.handleTour(val);
  }

  @Output() unFav = new EventEmitter();

  tour;
  tripType;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private service: Service,
    private toastr: ToastrService,
    private sessionManagement: SessionManagement
  ) {}

  ngOnInit() {}

  handleTour(trip) {
    if (trip.ActivityId) {
      this.tripType = "activity";
      trip = trip.Activity;
    } else if (trip.ActivityImages) {
      this.tripType = "activity";
      trip = trip;
    } else if (trip.PackageId) {
      this.tripType = "package";
      trip = trip.Package;
    } else if (trip.PackageImages) {
      this.tripType = "package";
      trip = trip;
    }
    if (this.tripType === "package") {
      trip.images = trip.PackageImages;
      if (trip.PackageCategoryMasters) {
        trip.categories = trip.PackageCategoryMasters.map((c) => {
          if (!c.Name) {
            return null;
          }
        }).filter((c) => c != null);
      } else {
        trip.categories = [];
      }

      trip.rate = trip.PackageRating;
    } else if (this.tripType === "activity") {
      trip.images = trip.ActivityImages;
      if (trip.ActivityCategories) {
        trip.categories = trip.ActivityCategories.map((c) => {
          if (!c.Name) {
            return null;
          }
        }).filter((c) => c != null);
      } else {
        trip.categories = [];
      }
      trip.rate = trip.ActivityRating;
    }
    trip.Offer = Number(trip.Offer);
    if (this.isFav) {
      trip.Favourite = { Id: this.isFav };
    } else {
      if (trip.Favourite) {
        trip.Favourite = Object.keys(trip.Favourite).length
          ? trip.Favourite
          : null;
      }
    }
    // console.log(trip);
    this.tour = trip;
  }

  toggleFavourite(tourId, Fav) {
    if (!this.sessionManagement.GetUser()) {
      this.errorToaster(
        this.translate.instant("Login or Register to use favorite feature")
      );
      return false;
    }
    const model = new Favourite();
    if (this.tripType === "package") {
      model.PackageId = tourId;
    }
    if (this.tripType === "activity") {
      model.ActivityId = tourId;
    }

    if (Fav) {
      this.service.FavouriteService.Delete(Fav.Id).subscribe((item: any) => {
        if (item.returnCode === 0) {
          // this.successToaster(item.returnMessage);
          this.tour.Favourite = null;
          this.unFav.emit(this.tour.Id);
        } else {
          this.errorToaster(item.returnMessage);
        }
      });
    } else {
      this.service.FavouriteService.Create(model).subscribe((item: any) => {
        if (item.returnCode === 0) {
          // this.successToaster(item.returnMessage);
          this.tour.Favourite = item.data;
        } else {
          this.errorToaster(item.returnMessage);
        }
      });
    }
  }

  navigate() {
    const params = {};
    if (this.adults) {
      params["adults"] = this.adults;
    }
    if (this.children) {
      params["children"] = this.children;
    }
    if (this.date) {
      params["date"] = this.date;
    }
    if (this.tripType === "package") {
      this.router.navigate(["/offer/detail"], {
        queryParams: { Package: this.tour.Id, ...params },
      });
    } else if (this.tripType === "activity") {
      this.router.navigate(["/activitypannel/detail"], {
        queryParams: { ActivityId: this.tour.Id, ...params },
      });
    }
  }

  errorToaster(message) {
    this.toastr.error(message, null, {
      timeOut: 5000,
      closeButton: false,
      positionClass: "toast-top-center",
    });
  }

  successToaster(message) {
    this.toastr.clear();
    this.toastr.success(message, null, {
      timeOut: 5000,
      closeButton: false,
      positionClass: "toast-top-center",
    });
  }
}
