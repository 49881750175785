import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { CampaignsService } from "src/app/services/campaigns.service";
import { MetaTagsService } from "src/app/services/meta-tags.service";

@Component({
  selector: "app-campaigns-view",
  templateUrl: "./campaigns-view.component.html",
  styleUrls: ["./campaigns-view.component.css"],
})
export class CampaignsViewComponent implements OnInit, OnDestroy {
  campaignId: number;
  campaign;

  constructor(
    private activatedRoute: ActivatedRoute,
    private campaignsService: CampaignsService,
    private translate: TranslateService,
    private metaTagsService: MetaTagsService
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      if (params && params.campaignId) {
        this.campaignId = Number(params.campaignId);
        this.getCampaign(this.campaignId);
      }
    });
  }

  getCampaign(campaignId) {
    this.campaignsService
      .getCampaignById(campaignId)
      .subscribe((result: any) => {
        this.campaign = result.data;
        this.setData(this.campaign);
      });
  }

  setData(data) {
    let title;
    let cities = [];
    let description;
    let desc;
    if (data) {
      data.Packages.map((p) =>
        p.Cities.map((c) => {
          if (!cities.includes(c.Name)) {
            cities.push(c.Name);
          }
        })
      ).join(",");
      // cities = cities.join(",");
      description = data.Description.replaceAll(/<[^>]*>/g, "");
      desc = `${this.translate.instant(
        "ASpecialGroupOfExcursionsAndTouristExperiences"
      )} - ${description} - ${cities}`;
    }
    title = `
    ${this.translate.instant(
      "ExcursionsAndSpecialExperiencesInSelectedDestinations"
    )} - ${cities}
    `;
    this.metaTagsService.setDescription(desc);
    this.metaTagsService.setTitle(title);
  }

  ngOnDestroy() {
    this.setData("");
  }
}
