import { Injectable, enableProdMode } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { PackageSearch } from "./models/package-search";
@Injectable({
  providedIn: "root",
})
export class Server {
  constructor(private http: HttpClient) {}
  Get(url: string) {
    // tslint:disable-next-line:prefer-const
    let promise = new Promise((resolve, reject) => {
      this.http
        .get(url)
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }
  GetPackagesFilter(url: string, model: PackageSearch) {}
  Post(url: string, model: any) {
    // tslint:disable-next-line:prefer-const
    let promise = new Promise((resolve, reject) => {
      this.http
        .post(url, model, { headers: { "Content-Type": "application/json" } })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }
  Put(url: string, model: any) {
    // tslint:disable-next-line:prefer-const
    let promise = new Promise((resolve, reject) => {
      this.http
        .put(url, model, { headers: { "Content-Type": "application/json" } })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }
  Delete(url: string) {
    // debugger;
    // tslint:disable-next-line:prefer-const
    let promise = new Promise((resolve, reject) => {
      this.http
        .delete(url)
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }
}
